import moment from "moment"

// export const dateFormat = (date) => {
//     return moment(date).format('DD/MM/YYYY')
// }

export const dateFormat = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return moment(date).format('DD/MM/YYYY');
}

export const timeFormat = (timestamp) => {
    const formattedTime = moment(timestamp).format("hh:mma");  
    return formattedTime;
}

export const dateTime = (date) => {
    return moment(date).format('DD/MM/YYYY | HH:mm:ss A');
}

export const copyText = (text, setCopyState) => {
    navigator.clipboard.writeText(text);
    return setCopyState("appear");
}


export const openLinkInNewTab = (link) => {
    // Check if the link starts with "http://" or "https://"
    if (!link?.startsWith("http://") && !link?.startsWith("https://")) {
        // If not, prepend "https://"
        link = "https://" + link;
    }

    return window.open(link, '_blank');
};

export const logout = (navigate, setLogoutPop, setIsLoggedIn) => {
    setIsLoggedIn(false)
    localStorage.clear();
    navigate('/');
    setLogoutPop(false);
}

export const isLoggedIn = () => {
    const loggedIn = localStorage.getItem('accessToken')
    if (!loggedIn || loggedIn === 'null' || loggedIn === '') {
        return false;
    } else {
        return true;
    }
}

export const openInMap = (lat, lon) => {
    const userAgent = navigator.userAgent;

    // Check if the user agent indicates an iOS device
    if (userAgent.includes("CriOS")) {
      // This is Google Chrome on iOS
      window.location.href = `maps://maps?q=${lat},${lon}`;
    } else if (
      userAgent.includes("Mozilla") &&
      (userAgent.includes("Android") || userAgent.includes("Linux"))
    ) {
      // It's Firefox on Android
      const url = `http://www.google.com/maps/search/${lat},${lon}`;
      window.open(url, "_blank");
    } else if (userAgent.includes("Mozilla") && userAgent.includes("FxiOS")) {
      // const url = `http://maps.apple.com/maps?q=${lat},${lon}`;
      // window.open(url, "_blank");
      // window.location.href = url
      window.location.href = `maps://maps?q=${lat},${lon}`;
    } else if (userAgent.includes("Safari") && !userAgent.includes("CriOS")) {
      // This is Safari on iOS
      const url = `http://maps.apple.com/maps?q=${lat},${lon}`;
      window.open(url, "_blank");
    } else if (
      userAgent.includes("Mozilla") &&
      userAgent.includes("Macintosh") &&
      userAgent.includes("Firefox")
    ) {
      // This is Firefox on Mac OS
      const url = `http://maps.apple.com/maps?q=${lat},${lon}`;
      window.open(url, "_blank");
    } else {
      // It's neither Chrome nor Safari on iOS
      const url = `https://www.google.com/maps/search/${lat},${lon}`;
      window.open(url, "_blank");
    }
}

export const userLocation = (city, country) => {
    const location = "-";
    if (city && country) return city + ", " + country;
    if (city && !country) return city;
    if (!city && country) return country;
    return location;
}

// CHECKING VALID GMAIL
export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const capitalizeWords = (str) => {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}