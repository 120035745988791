import React from 'react'
import InfoHeader from './Components/InfoHeader'
import Info from './Components/Info'
import Spinner from 'react-bootstrap/Spinner'
import DefaultStaticPopover from '../../../../../../components/DefaultStaticPopover/DefaultStaticPopover'

const PersonalInfo = ({ userDetails, userDetailsLoader }) => {
    const { personalDetails } = userDetails

    return (
        <>
            <div className='Info-wrapper'>
                <InfoHeader title='Personal details' />
                <div className='loginDetails-wrapper row'>
                    {userDetailsLoader ? <div className="personal-details-spinner-container">
                        <Spinner animation="border" variant="danger" className="personal-details-spinner-loader" />
                    </div> : <>
                        <div className='detail-wrapper mb-2 col-lg-6 col-md-6 col-xl-4 col-12'>
                            <Info title={"Username"} value={personalDetails?.username} isPopup/>
                            <Info title={"Gender"} value={personalDetails?.gender} caps={true} />
                            <Info title={"Full name"} value={personalDetails?.firstname + " " + personalDetails?.lastname} isPopup/>
                            <Info title={"Birthdate"} value={personalDetails?.dob} />
                        </div>
                        <div className='detail-wrapper mb-2 col-lg-6 col-md-6 col-xl-4 col-12'>
                            <Info title={"Address"} value={personalDetails?.address} map={true} link address/>
                            <Info title={"Phone no"} value={personalDetails?.phone} />
                            <Info title={"Mail"} value={personalDetails?.email} />
                            <Info title={"Website"} value={personalDetails?.website} link />
                        </div>
                        <div className='detail-wrapper mb-2 col-lg-6 col-md-6 col-xl-4 col-12'>
                            <Info title={"Aadhar verification status"} value={personalDetails?.aadhaarVerificationStatus ? "Verified" : "Not verified"} />
                            <Info title={"Alternate phone no"} value={personalDetails?.alternatePhone} />
                            <Info title={"Alternate mail"} value={personalDetails?.alternameEmail} />
                            <Info title={"Social media link"} value={personalDetails?.socialMediaURL} link/>
                        </div>
                        
                        


                    </>}
                </div>
            </div>
        </>
    )
}

export default PersonalInfo
