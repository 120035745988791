import "./App.scss";
import Routing from "./Routing";
import { createContext, useState } from "react";

export const LogoutPopUp = createContext();
export const Auth = createContext();
export const SessionExpired = createContext();

function App() {
  const [logoutPop, setLogoutPop] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [sessionExpired, setSessionExpired] = useState(false);

  console.log('PRETEST ENV: 26 DEC')
  return (
    <Auth.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <LogoutPopUp.Provider value={{ logoutPop, setLogoutPop }}>
        <SessionExpired.Provider value={{ sessionExpired, setSessionExpired }}>
          <Routing />
        </SessionExpired.Provider>
      </LogoutPopUp.Provider>
    </Auth.Provider>
  );
}

export default App;
